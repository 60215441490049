import axios from "@/axios.js"

export default {
  getCategories({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios.get(`marketplace/category${params}`)
      .then((response) => {
        if (!response.data.status)
          return reject(response.data.msg)

        commit('SET_CATEGORIES', response.data.data)
        return resolve(response.data)
      })
      .catch(error => {reject(error)})
    })
  },
  addCategory({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post("/marketplace/category", item)
        .then((response) => {
          if (response.status) {
            commit('ADD_CATEGORY', response.data.data)
            resolve(response)
          } else {
            reject(response.msg)
          }
        })
        .catch((error) => { reject(error) })
    })
  },
  updateCategory({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.put(`/marketplace/category`, item)
        .then((response) => {
          commit('UPDATE_CATEGORY', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  deleteCategory({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/data-list/categories/${itemId}`)
        .then((response) => {
          commit('DELETE_CATEGORY', itemId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getStores({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios.get(`marketplace/store${params}`)
      .then((response) => {
        if (!response.data.status)
          return reject(response.data.msg)

        commit('SET_STORES', response.data.data)
        return resolve(response.data)
      })
      .catch(error => {reject(error)})
    })
  },
  addStore({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post(`marketplace/store`, item)
        .then((response) => {
          if(!response.data.status)
            return reject(response.data.msg)

          commit('ADD_STORE', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  addCommerceStore({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post(`commerce/store`, item)
        .then((response) => {
          if(!response.data.status)
            return reject(response.data.msg)

          commit('ADD_STORE', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateCommerceStore({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.put(`commerce/store`, item)
        .then((response) => {
          if(!response.data.status)
            return reject(response.data.msg)

          commit('UPDATE_STORE', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateStore({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.put(`marketplace/store`, item)
        .then((response) => {
          if(!response.data.status)
            return reject(response.data.msg)

          commit('UPDATE_STORE', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  deleteStore({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.delete(`marketplace/store`, { data: item })
        .then((response) => {
          if(!response.data.status)
            return reject(response.data.msg)

          commit('UPDATE_STORE', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getProducts({ commit }, idStore) {
    return new Promise((resolve, reject) => {
      axios.get(`marketplace/product?id_store=${idStore}`)
        .then(response => {
          if(!response.data.status)
            return reject(response.data.msg)

          commit('SET_PRODUCTS', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  addProduct({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post("/marketplace/product", item)
        .then((response) => {
          if (!response.data.status)
            return reject(response.data.msg)

          commit('ADD_PRODUCT', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateProduct({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.put(`marketplace/product`, item)
        .then((response) => {
          if(!response.data.status)
            return reject(response.data.msg)

          commit('UPDATE_PRODUCT', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  patchProduct({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`marketplace/product`, item)
        .then((response) => {
          if(!response.data.status)
            return reject(response.data.msg)

          commit('UPDATE_PRODUCT', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  deleteProduct({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.delete(`marketplace/product`, { data: item })
        .then((response) => {
          if(!response.data.status)
            return reject(response.data.msg)

          commit('UPDATE_PRODUCT', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}
