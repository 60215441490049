import axios from "../../axios.js"

export default {
  // addItem({ commit }, item) {
  //   return new Promise((resolve, reject) => {
  //     axios.post("/api/data-list/products/", {item: item})
  //       .then((response) => {
  //         commit('ADD_ITEM', Object.assign(item, {id: response.data.id}))
  //         resolve(response)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },
  // fetchUsers({ commit }) {
    // return new Promise((resolve, reject) => {
    //   axios.get("/api/user-management/users")
    //     .then((response) => {
    //       commit('SET_USERS', response.data)
    //       resolve(response)
    //     })
    //     .catch((error) => { reject(error) })
    // })
    // return new Promise((resolve, reject) => {
    //   axios.get("https://run.mocky.io/v3/143fb936-68df-46ce-bd4d-275a2249e3bf")
    //     .then((response) => {
    //       commit('SET_USERS', response.data.users)
    //       resolve(response)
    //     })
    //     .catch((error) => { reject(error) })
    // })
  // },
  getStaff({ commit }, idInst) {
    return new Promise((resolve, reject) => {
      axios.get(`pos/staff/${idInst}`)
      .then((response) => {
        commit('SET_USERS', response.data.data)
        resolve(response)
      })
      .catch(error => {reject(error)})
    })
  },
  fetchUser(context, userId) {
    return new Promise((resolve, reject) => {
      axios.get(`/account/data/${userId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  deleteUser({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/pos/staff/${userId}`)
        .then((response) => {
          if(!response.data.status)
            return reject(response.data.msg)

          commit('REMOVE_RECORD', userId)
          return resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  addUser({ commit }, user_data) {
    return new Promise((resolve, reject) => {
      axios.put(`pos/staff`, user_data)
      .then(response => {
        if(!response.data.status) {
          reject(response.data.msg);
        }
        commit('ADD_USER', response.data.data);   //user_data
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      })
    })
  },
  updateUser({commit}, user_data) {
    return new Promise((resolve, reject) => {
      axios.post(`pos/staff/${user_data.id}`, user_data)
        .then(response => {
          if (!response.data.status)
            return reject(response.data.msg)

          if(response.data.data.profile.profile_type == 3 || response.data.data.profile.profile_type == 6 || response.data.data.profile.profile_type == 7) {
            commit('UPDATE_OWNER', response.data.data);
          } else {
            commit('UPDATE_USER', response.data.data);
          }

          resolve(response.data);

        })
        .catch(error => reject(error))
    })
  },
  genPassword() {
    return new Promise((resolve, reject) => {
      axios.get(`pos/password`)
      .then(response => {
        if(!response.status)
          reject(response.data.msg);

        resolve(response.data.data)
      })
      .catch(error => {
        reject(error);
      })
    })
  },
  genUsername(user_names) {
    return new Promise((resolve,reject) => {
      axios.get(`pos/username`, user_names)
        .then(response => {
          if (!response.data.status)
            reject(response.data.msg)

          resolve(response.data.data)
        })
        .catch(error => {reject(error)})
    })
  },
  passwordrecovery(context, email) {
    return new Promise((resolve,reject) => {
      axios.post(`account/password/recovery/staff`, email)
        .then(response => {
          if(!response.data.status)
            return reject(response.data.msg)

          return resolve(response.data)
        })
        .catch(error => console.log(error))
    })
  },
  passwordrecoverycheck({commit}, payload) {
    return new Promise((resolve,reject) => {
      axios.post(`account/password/recovery/check`, payload)
        .then(response => {
          if(!response.data.status)
            return reject(response.data.msg)

          commit("auth/SET_BEARER", response.data.data.auth.token, { root: true })

          return resolve(response.data)
        })
        .catch(error => console.log(error))
    })
  },
  passwordrecoveryset(context, payload) {
    return new Promise((resolve,reject) => {
      axios.post(`account/password/set`, payload)
        .then(response => {
          if(!response.data.status)
            return reject(response.data.msg)

          return resolve(response.data)
        })
        .catch(error => console.log(error))
    })
  },
  changePassword(context, user_data) {
    return new Promise((resolve, reject) => {
      axios.post(`account/password/change`, user_data)
      .then(response => {
        if(!response.data.status) {
          reject(response.data.msg);
        }
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      })
    })
  },
  activateUser(context, email) {
    return new Promise((resolve,reject) => {
      axios.post(`account/activate/${email}`)
        .then(response => {
          if(!response.data.status)
            reject(response.data.msg)

          resolve(response.data)
        })
    })
  },
  getOwners({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`pos/owners`)
        .then((response) => {
          if(!response.data.status)
            reject(response.data.msg)

          commit('SET_OWNERS', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  accountSearch(ctx, payload) {
    return new Promise((resolve, reject) => {
      axios.get('reports/account/filter', { params: payload })
        .then(response => {
          if(!response.data.status)
            reject(response.data.msg)

          resolve(response.data.data.account)
        })
        .catch(err => reject(err))
    })
  }
}
