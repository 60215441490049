export default {
    ADD_CATEGORY(state, item) {
      state.categories.unshift(item)
    },
    SET_CATEGORIES(state, categories) {
      state.categories = categories
    },
    UPDATE_CATEGORY(state, category) {
        const categoryIndex = state.categories.findIndex((p) => p.id == category.id)
        Object.assign(state.categories[categoryIndex], category)
    },
    DELETE_CATEGORY(state, itemId) {
        const ItemIndex = state.categories.findIndex((p) => p.id == itemId)
        state.categories.splice(ItemIndex, 1)
    },
    SET_STORES(state, stores) {
      // Add store type in root object as a workaround for vuesax vs-table sort-key issue (can't sort by an object property i.e. store.commerce.id_type)
      state.stores = stores.map(store => {
        store.type_id = store.commerce.id_type
        return store
      })
    },
    ADD_STORE(state, item) {
      state.stores.unshift(item)
    },
    ADD_SCHOOL(state, item) {
      state.schools.unshift(item)
    },
    UPDATE_STORE(state, payload) {
      const idx = state.stores.findIndex((p) => p.id == payload.id)
      Object.assign(state.stores[idx], payload)
    },
    ADD_PRODUCT(state, item) {
      state.products.unshift(item)
    },
    SET_PRODUCTS(state, products) {
      state.products = products
    },
    UPDATE_PRODUCT(state, payload) {
      const idx = state.products.findIndex((p) => p.id == payload.id)
      Object.assign(state.products[idx], payload)
    },
  }
