import state from './moduleMarketplaceState.js'
import mutations from './moduleMarketplaceMutations.js'
import actions from './moduleMarketplaceActions.js'
import getters from './moduleMarketplaceGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
