<template>
  <div id="store-add-edit-tab-info">
    <div class="mt-4">
      <label>Tipo de Tienda</label>
      <div class="flex flex-wrap mt-3 mb-6">
        <vs-radio v-model="data_local.commerce.id_type" vs-name="commerce-type" :vs-value="1" class="mr-4" :disabled="this.data_local.id">Escuela</vs-radio>
        <vs-radio v-model="data_local.commerce.id_type" vs-name="commerce-type" :vs-value="2" class="mr-4" :disabled="this.data_local.id">Comercio</vs-radio>
      </div>
    </div>
    <!-- Avatar Row -->
    <div class="vx-row" v-if="data_local.commerce.id_type != 1">
      <div class="vx-col w-full">
        <div class="flex items-start flex-col sm:flex-row">
          <img v-if="data_local.commerce.image || dataImage" :src="image_loaded ? dataImage : imgPath + data_local.commerce.image" class="mr-8 rounded h-24 w-24" />
          <div v-else class="mr-8 rounded h-24 w-24 primary flex items-center flex-col justify-center" style="background-color: #f5f5f5"><feather-icon icon="ImageIcon" style="color:lightgray"></feather-icon></div>
          <span class="text-danger text-sm" v-show="errors.has('school_image')">{{ errors.first('school_image') }}</span>
          <div>
            <p class="text-lg font-medium mb-2 mt-4 sm:mt-0">{{ dataName }}</p>
            <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept="image/*">

            <!-- Toggle comment of below buttons as one for actual flow & currently shown is only for demo -->
            <vs-button class="mr-4 mb-4" @click="$refs.updateImgInput.click()">Actualizar Imagen</vs-button>
            <vs-button type="border" color="danger" v-if="image_loaded" @click="removeImage">Remover Imagen</vs-button>
          </div>
        </div>
        <vs-alert v-show="image_error" color="danger" style="height: initial" icon-pack="feather" icon="icon-info">
          {{ image_error }}
        </vs-alert>
      </div>
    </div>

    <!-- Content Row -->
    <div class="vx-row mt-4">
      <div class="vx-col md:w-1/2 w-full">
        <div class="vx-col w-full">
          <div class="flex items-end px-3">
            <feather-icon svgClasses="w-6 h-6" icon="InfoIcon" class="mr-2" />
            <span class="font-medium text-lg leading-none">Datos Generales</span>
          </div>
          <vs-divider />
        </div>

        <!-- SCHOOL -->
        <div class="mt-4" v-if="data_local.commerce.id_type == 1">
          <label class="vs-input--label">Escuela</label>
          <vs-input v-if="editMode" class="w-full" v-model="data_local.commerce.name" v-validate="'required'" data-vv-as="Escuela" name="school_name" autocomplete="off" disabled="disabled"/>
          <v-select v-else v-model="data_local.commerce.id_commerce" :clearable="false" :options="schoolOptions" :reduce="dataIdCommerce => dataIdCommerce.value" v-validate="'required'" data-vv-as="Tienda" name="id_commerce" :dir="$vs.rtl ? 'rtl' : 'ltr'" :disabled="!!this.data_local.id"/>
          <span class="text-danger text-sm"  v-show="errors.has('id_commerce')">{{ errors.first('id_commerce') }}</span>
        </div>

        <!-- STORE NAME -->
        <vs-input class="w-full mt-4" label="Nombre de la Tienda" v-model="data_local.name" v-validate="{required: true, regex: /^[a-zA-Z0-9ñÑáÁéÉíÍóÓúÚ ]+$/}" data-vv-as="Nombre de la Tienda" name="store_name" />
        <span class="text-danger text-sm"  v-show="errors.has('store_name')">{{ errors.first('store_name') }}</span>

        <!-- ADMIN -->
        <div class="mt-4">
          <label class="vs-input--label">Administrador de la Tienda</label>
          <v-select multiple :closeOnSelect="false" v-model="owner_local" :clearable="false" :options="ownerOptions" :reduce="dataIdOwner => dataIdOwner.value" v-validate="'required'" data-vv-as="Administrador" name="id_owner" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
          <span class="text-danger text-sm"  v-show="errors.has('id_owner')">{{ errors.first('id_owner') }}</span>
        </div>

        <!-- AVAILABLE SCHOOLS -->
        <div class="flex flex-wrap mt-4" v-if="data_local.commerce.id_type != 1">
          <div class="w-1/4">
            <label style="font-size:.85rem">Escuelas Habilitadas</label>
            <div class="mt-2">
              <vs-radio v-model="data_local.all_schools" vs-name="schools-selected" :vs-value="true" class="mr-4">Todas</vs-radio>
              <vs-radio v-model="data_local.all_schools" vs-name="schools-selected" :vs-value="false" class="mr-4">Algunas</vs-radio>
            </div>
          </div>
          <div class="w-3/4 mt-5" v-if="!data_local.all_schools">
            <v-select multiple :closeOnSelect="false" v-model="schools_local" :clearable="false" :options="schoolsAllOptions" :reduce="dataIdSchool => dataIdSchool.value" v-validate="'required'" data-vv-as="Escuelas" name="id_schools" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
            <span class="text-danger text-sm"  v-show="errors.has('id_schools')">{{ errors.first('id_schools') }}</span>
          </div>
        </div>

        <!-- COMMISSION -->
        <div class="mt-4 w-full">
          <label class="vs-input--label">Comisión Tiptap</label>
          <div class="flex flex-wrap">
            <vs-input-number @input="checkMinValue" label="%" v-model="data_local.tiptap_commission_percent" :step="0.125" v-validate="'min:0|min_value:0'" data-vv-as="Comisión Tiptap" name="tiptap_commission" class="w-100"/>
            <span class="text-danger text-sm w-full"  v-show="errors.has('tiptap_commission')">{{ errors.first('tiptap_commission') }}</span>
          </div>
        </div>

        <!-- ADDRESS -->
        <div class="mt-4 flex flex-wrap" v-if="data_local.commerce.id_type != 1">
          <div class="w-1/2">
            <vs-input class="w-full" label="Dirección" v-model="data_local.commerce.address" name="address" autocomplete="off" v-validate="'required'" data-vv-as="Dirección"/>
            <span class="text-danger text-sm"  v-show="errors.has('address')">{{ errors.first('address') }}</span>
          </div>
          <div class="w-1/4 pl-4">
            <vs-input class="w-full" label="Ciudad" v-model="data_local.commerce.city" name="city" placeholder="Ciudad" autocomplete="off" v-validate="'required'" data-vv-as="Ciudad"/>
            <span class="text-danger text-sm"  v-show="errors.has('city')">{{ errors.first('city') }}</span>
          </div>
          <div class="w-1/4 pl-4">
            <vs-input class="w-full" label="Código Postal" v-model="data_local.commerce.zip_code" name="zip_code" placeholder="Código Postal" autocomplete="off" v-validate="'required'" data-vv-as="Código Postal"/>
            <span class="text-danger text-sm"  v-show="errors.has('zip_code')">{{ errors.first('zip_code') }}</span>
          </div>
        </div>
      </div>

      <!-- BANK ACCOUNT -->
      <div class="vx-col md:w-1/2 w-full">

        <div class="vx-col w-full">
          <div class="flex items-end px-3">
            <feather-icon svgClasses="w-6 h-6" icon="LockIcon" class="mr-2" />
            <span class="font-medium text-lg leading-none">Cuenta Bancaria</span>
          </div>
          <vs-divider />
        </div>

        <div class="mt-4">
          <vs-input class="w-full mt-4" label="Banco" v-model="data_local.bank_name" v-validate="'required'" data-vv-as="Banco" name="bank_name" autocomplete="off"/>
          <span class="text-danger text-sm"  v-show="errors.has('bank_name')">{{ errors.first('bank_name') }}</span>
        </div>

        <div class="mt-4">
          <label style="font-size:.85rem">Tipo de Cuenta</label>
          <div class="flex flex-wrap mt-3 mb-6">
            <vs-radio v-model="data_local.account_type" vs-name="bank-account-type" :vs-value="0" class="mr-4">Caja de Ahorro</vs-radio>
            <vs-radio v-model="data_local.account_type" vs-name="bank-account-type" :vs-value="1" class="mr-4">Cuenta Corriente</vs-radio>
          </div>
        </div>

        <div class="mt-4">
          <vs-input class="w-full mt-4" label="Nombre de la Cuenta" v-model="data_local.account_name" v-validate="'required'" data-vv-as="Nombre de la Cuenta" name="account_name" autocomplete="off"/>
          <span class="text-danger text-sm"  v-show="errors.has('account_name')">{{ errors.first('account_name') }}</span>
        </div>

        <div class="mt-4">
          <vs-input class="w-full mt-4" label="Número de Cuenta" v-model="data_local.account_number" v-validate="'required'" data-vv-as="Número de Cuenta" name="bank_account" autocomplete="off"/>
          <span class="text-danger text-sm"  v-show="errors.has('bank_account')">{{ errors.first('bank_account') }}</span>
        </div>

      </div>

    </div>

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" @click="save_changes" :disabled="!validateForm || loading">{{ !!data_local.id ? 'Editar' : 'Agregar'}}</vs-button>
          <vs-button class="ml-4 mt-2" type="border" color="warning" @click="reset_data" :disabled="loading">Cancelar</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import moduleDataListInstitutions from "@/store/data-list-institutions/moduleDataList.js"
import moduleUserManagement from "@/store/user-management/moduleUserManagement.js"

export default {
  components: {
    vSelect
  },
  props: {
    data: {
      type: Object | Boolean,
      required: true,
    },
  },
  data() {
    return {
      editMode: true,
      imgPath: this.$baseUrlImg,
      image_loaded: false,
      image_error: '',
      image_settings: {
        size: '',
        width: '',
        height: ''
      },

      //data
      data_local: JSON.parse(JSON.stringify(this.data)),
      owner_local: this.data.admins,
      schools_local: this.data.store_commerces,
      dataId: null,
      dataIdCommerce: null,
      dataName: null,
      dataIdOwner: [],
      dataTiptapCommission: null,
      dataAddress: null,
      dataCity: null,
      dataZipcode: null,
      dataBankId: null,
      dataBankName: null,
      dataAccountType: 0,
      dataAccountName: null,
      dataAccountNumber: null,
      dataImage: null,
      dataCollectors: [],
      dataCommerceIdType: null,

      ownerOptions: [],
      schoolOptions: [],
      schoolsAllOptions: [],

      statusOptions: [
        { label: "Active",  value: "active" },
        { label: "Blocked",  value: "blocked" },
        { label: "Deactivated",  value: "deactivated" },
      ],
      roleOptions: [
        { label: "Admin",  value: "admin" },
        { label: "User",  value: "user" },
        { label: "Staff",  value: "staff" },
      ],

      loading: false
    }
  },
  computed: {
    status_local: {
      get() {
        return this.data_local.status
      },
      set(obj) {
        this.data_local.status = obj.value
      }
    },
    id_commerce_local: {
      get() {
        return { label: this.capitalize(this.data_local.commerce.name),  value: this.data_local.commerce.id_commerce  }
      },
      set(obj) {
        this.data_local.id_commerce = obj.value
      }
    },
    validateForm() {
      // this.$validator.validateAll()
      return !this.errors.any()
    }
  },
  methods: {
    capitalize(str) {
      return str.slice(0,1).toUpperCase() + str.slice(1,str.length)
    },
    save_changes() {
      this.$validator.validateAll().then(() => {
          if(!this.validateForm) return

          let obj = {}

          let action = "updateStore"

          if (!this.editMode) {
            const { name, tiptap_commission_percent, commerce, bank_name, account_name, account_number, account_type } = this.data_local

            // For commerce type, creates commerce and store
            if (commerce.id_type == 2) {
              obj = {
                commerce: { ...commerce, name, bank_name, account_name, account_number, account_type },
                store: {
                  name,
                  tiptap_commission_percent,
                  admins: this.owner_local,
                  all_schools: this.data_local.all_schools,
                  store_commerces: this.schools_local
                }
              }

              action = "addCommerceStore"
            }

            // For school type, only adds the store (the commerce already exists)
            if (commerce.id_type == 1) {
              obj = {
                id_commerce: commerce.id_commerce,
                tiptap_commission_percent,
                name,
                admins: this.owner_local,
                bank_account: {
                  bank_name,
                  account_name,
                  account_number,
                  account_type
                }
              }

              action = "addStore"
            }
          } else {
            // Edit mode
            if (this.data_local.commerce.id_type == 2) {
              // Edit Commerce Store
              action = "updateCommerceStore"
              obj = {
                bank_account: {
                  id_bank: this.data_local.id_bank,
                  bank_name: this.data_local.bank_name,
                  account_type: this.data_local.account_type,
                  account_name: this.data_local.account_name,
                  account_number: this.data_local.account_number
                },
                commerce: {
                  id_commerce: this.data_local.commerce.id_commerce,
                  name: this.data_local.commerce.name,
                  address: this.data_local.commerce.address,
                  city: this.data_local.commerce.city,
                  zip_code: this.data_local.commerce.zip_code
                },
                store: {
                  id_store: this.data_local.id,
                  name: this.data_local.name.trim(),
                  tiptap_commission_percent: this.data_local.tiptap_commission_percent,
                  admins: this.owner_local,
                  all_schools: this.data_local.all_schools,
                  store_commerces: this.schools_local
                }
              }
            }

            if (this.data_local.commerce.id_type == 1) {
              // Edit school store
              obj = {
                id_store: this.data_local.id,
                tiptap_commission_percent: this.data_local.tiptap_commission_percent,
                name: this.data_local.name.trim(),
                admins: this.owner_local,
                bank_account: {
                  id_bank: this.data_local.id_bank,
                  bank_name: this.data_local.bank_name,
                  account_type: this.data_local.account_type,
                  account_name: this.data_local.account_name,
                  account_number: this.data_local.account_number
                },
              }
            }

          }

          if (this.image_loaded && this.image_error == '')
            obj.commerce.image = this.dataImage

          this.loading = true

          this.$store.dispatch(`marketplace/${action}`, obj)
          .then(response => {
            if(response.data.status) {
              this.$emit("showNotify", 'success', null, 'Se han guardado los cambios.')
              this.$store.dispatch("userManagement/getOwners")
              this.$router.push('/marketplace/stores')
            } else {
              this.$emit("showNotify", 'danger', 'Error', response.data.msg)
            }
          })
          .finally(() => this.loading = false)
          .catch(err => { this.$emit("showNotify", 'danger', 'Error', err) })
      })

    },
    reset_data() {
      //this.initValues()
      this.$router.push('/marketplace/stores')
    },
    initValues() {
      this.image_loaded = false

      this.data_local = {
        commerce: {
          name: "",
          id_type: 1,
          address: "",
          city: "",
          zip_code: "",
          image: "",
          bank_name: "",
          account_type: 0,
          account_name: "",
          account_number: ""
        },
        name: "",
        tiptap_commission_percent: 0,
        all_schools: true,
        bank_name: "",
        account_type: 0,
        account_name: "",
        account_number: ""
      }
    },
    updateCurrImg(input) {
      this.image_loaded = false;
      this.image_error = '';
      if (input.target.files && input.target.files[0]) {
        this.image_settings.size = input.target.files[0].size;
        var reader = new FileReader()
        reader.onload = e => {
          let img = new Image();
          img.onload = () => {
            this.image_settings.width = img.width;
            this.image_settings.height = img.height;
            if (this.image_settings.width != this.image_settings.height || this.image_settings.width > 300 ) {
              this.image_error = 'La imágen debe ser cuadrada (misma altura y ancho) con un máximo de 300px';
            } else {
              if (this.image_settings.size > 100000){
                this.image_error = 'La imágen excede el tamaño máximo (100 KB).';
              } else {
                this.dataImage = e.target.result
                this.image_loaded = true;
              }
            }
          }
          img.src = e.target.result;
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    removeImage() {
      this.dataImage = ''
      this.image_loaded = false
    },
    getSchoolChoices() {
      if(this.schoolOptions && this.schoolOptions.length) return

      const schools = this.$store.getters['dataListInstitutions/getSchools'];
      const stores = this.$store.getters['marketplace/getStores']
      const available_schools = schools.filter(({ id_commerce: id1 }) => !stores.some(({ id_commerce: id2 }) => id2 === id1));

      if (available_schools && available_schools.length > 0) {
        available_schools.forEach(element => {
          this.schoolOptions.push({label: element.name, value: element.id})
        });
      }
    },
    getOwnerChoices() {
      if(this.owner_choices && this.owner_choices.length) return

      const owners = this.$store.getters['userManagement/getAvailableStoreAdmins'](this.data_local.admins ? this.data_local.admins : [0]);

      owners.forEach(element => {
        this.ownerOptions.push({label: element.full_name, value: element.id})
      })
    },
    getAllSchoolsChoices() {
      const schools = this.$store.getters['dataListInstitutions/getSchools'];

      if (schools && schools.length > 0) {
        schools.forEach(school => this.schoolsAllOptions.push({label: school.name, value: school.id}))
      }
    },
    checkMinValue(val) {
      if (val < 0 || isNaN(val)) {
        this.dataTiptapCommission = 0
      }
    }
  },
  created() {
    // Register Institutions Module
    if(!moduleDataListInstitutions.isRegistered) {
      this.$store.registerModule('dataListInstitutions', moduleDataListInstitutions)
      moduleDataListInstitutions.isRegistered = true
    }

    if(!moduleUserManagement.isRegistered) {
      this.$store.registerModule('userManagement', moduleUserManagement)
      moduleUserManagement.isRegistered = true
    }

    if (!this.data_local) {
      this.editMode = false
      this.initValues()
    }

    if (this.data_local.commerce.id_type == 1)
      this.getSchoolChoices()

    this.getAllSchoolsChoices()
    this.getOwnerChoices()
  }
}
</script>
<style>
#store-add-edit-tab-info ul.vs__dropdown-menu {
  max-height: 170px;
}
</style>
