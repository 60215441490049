export default {
    ADD_ITEM(state, item) {
      state.institutions.unshift(item)
    },
    SET_INSTITUTIONS(state, institutions) {
      state.institutions = institutions
    },
    UPDATE_INSTITUTION(state, institution) {
        const institutionIndex = state.institutions.findIndex((p) => p.id == institution.id)
        Object.assign(state.institutions[institutionIndex], institution)
    },
    REMOVE_ITEM(state, itemId) {
        const ItemIndex = state.institutions.findIndex((p) => p.id == itemId)
        state.institutions.splice(ItemIndex, 1)
    },
    SET_SCHOOLS(state, payload) {
      state.schools = payload
    },
    ADD_SCHOOL(state, item) {
      state.schools.unshift(item)
    },
    UPDATE_SCHOOL(state, payload) {
      const idx = state.schools.findIndex((p) => p.id == payload.id)
      Object.assign(state.schools[idx], payload)
    },
}