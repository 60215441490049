<template>
  <div id="page-school-edit">

    <vs-alert color="danger" title="School Not Found" :active.sync="store_not_found">
      <span>Tienda con id {{ $route.params.idStore }} no encontrada. </span>
      <span>
        <span>Ir a </span><router-link to="/marketplace/stores" class="text-inherit underline">Tiendas</router-link>
      </span>
    </vs-alert>

    <vx-card>

      <div v-if="!store_not_found" slot="no-body" class="tabs-container px-6 pt-6">
        <h5 class="my-3">{{!$route.params.idStore ? 'Agregar Nueva' : 'Editar'}} Tienda</h5>
        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Información Básica" icon-pack="feather" icon="icon-info">
            <div class="tab-text">
              <store-add-edit-tab-information class="mt-4" :data="store_data" @showNotify="showNotify" />
            </div>
          </vs-tab>
        </vs-tabs>

      </div>

    </vx-card>
  </div>
</template>

<script>
import StoreAddEditTabInformation from "./AddEditTabInformation.vue"
import moduleMarketplace from '@/store/marketplace/moduleMarketplace.js'
// Store Module

export default {
  components: {
    StoreAddEditTabInformation
  },
  data() {
    return {
      store_data: false,
      store_not_found: false,

      /*
        This property is created for fetching latest data from API when tab is changed

        Please check it's watcher
      */
      activeTab: 0,
    }
  },
  watch: {
    activeTab() {
      // this.fetch_store_data(this.$route.params.userId)
    }
  },
  methods: {
    fetch_data(id) {
      const store = this.$store.getters['marketplace/getStore'](id)

      if (!store)
        this.store_not_found = true

      this.store_data = store
    },
    showNotify(type,title,text) {
      this.$vs.notify({
        color: type || 'success',
        title: title || 'Correcto!',
        text: text || '',
        time: 8000,
        position: 'top-center',
      })
    },
  },
  created() {
    // Register Marketplace Module
    if(!moduleMarketplace.isRegistered) {
        this.$store.registerModule('marketplace', moduleMarketplace)
        moduleMarketplace.isRegistered = true
    }

    if (this.$route.params.idStore)
      this.fetch_data(this.$route.params.idStore)
  }
}

</script>
