import axios from "@/axios.js"

export default {
  addInstitution({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.put("pos/institutions", item)
        .then((response) => {
            if (response.data.status)
                commit('ADD_ITEM', response.data.data)

            resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  getInstitutions({ commit, rootGetters }) {
    return new Promise((resolve, reject) => {
      axios.get(`pos/institutions`)
        .then((response) => {
          const owners = rootGetters['userManagement/getOwners'];
          let institutions = response.data.data;
          institutions.forEach(element => {
            var owner_element = owners.find((owner) => owner.id == element.id_owner)
            element.name_owner = owner_element ? owner_element.full_name : ""
          });
          commit('SET_INSTITUTIONS', institutions)
          resolve(institutions)
        })
        .catch((error) => { reject(error) })
    })
  },
  getSchools({ commit, dispatch, rootGetters }) {
    return new Promise((resolve, reject) => {
      axios.get(`school`)
        .then((response) => {
          const owners = rootGetters['userManagement/getOwners'];
          if(owners === undefined || owners.length == 0) {
            return new Promise((resolve) => {
              return dispatch('userManagement/getOwners', null, { root: true })
                .then(() => {
                  return resolve(response)
                })
                .catch(err => reject(err))
            })
          } else {
            return response
          }
        })
        .then((response) => {
          const owners = rootGetters['userManagement/getOwners'];
          let institutions = response.data.data;
          institutions.forEach(element => {
            var owner_element = owners.find((owner) => owner.id == element.collectors[0].id_account)
            element.name_owner = owner_element ? owner_element.full_name : ""
          });
          commit('SET_SCHOOLS', institutions)
          resolve(institutions)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateInstitution({ commit, rootGetters }, item) {
    return new Promise((resolve, reject) => {
      axios.post(`pos/institutions/${item.id}`, item)
        .then((response) => {
          if(response.data.status) {
            const owners = rootGetters['userManagement/getOwners'];
            let institution = response.data.data;
            let owner_element = owners.find((owner) => owner.id == institution.id_owner)
            institution.name_owner = owner_element ? owner_element.full_name : ""

            commit('UPDATE_INSTITUTION', response.data.data)
          }
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  // getOwners({ commit }) {
  //   return new Promise((resolve, reject) => {
  //       axios.get(`pos/owners`)
  //         .then((response) => {
  //           commit('SET_OWNERS', response.data.data)
  //           resolve(response.data)
  //         })
  //         .catch((error) => { reject(error) })
  //   })
  // },
  getPendingPayments(context, idOwner) {
    return new Promise((resolve, reject) => {
      axios.get(`pos/payment/request/${idOwner}`)
        .then(response => {
          if(!response.data.status)
            reject(response.msg)

          resolve(response.data.data);
        })
        .catch(error => console.log(error))
    })
  },
  getRegisteredStudents(context, idInstitution) {
    return new Promise((resolve, reject) => {
      axios.get(`pos/student/institution/${idInstitution}`)
        .then(response => {
          if(!response.data.status)
            reject(response.data.msg)

          resolve(response.data);
        })
        .catch(error => console.log(error))
    })
  },
  // addSchool({ commit }, payload) {
  //   return new Promise((resolve, reject) => {
  //     axios.put("pos/main_institution", payload)
  //       .then((response) => {
  //           if (response.data.status)
  //               commit('ADD_SCHOOL', response.data.data)

  //           resolve(response.data)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },
  addSchool({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/school/add", payload)
        .then((response) => {
            if (!response.data.status)
                reject(response.data.msg)

            commit('ADD_SCHOOL', response.data.data)
            resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateSchool({ commit, rootGetters }, item) {
    return new Promise((resolve, reject) => {
      axios.post(`school/modify`, item)
        .then((response) => {
          if(response.data.status) {
            const owners = rootGetters['userManagement/getOwners'];
            // let institution = {...item.school, bank: item.bank_account, collectors: item.collectors};
            let institution = response.data.data[0]
            let owner_element = owners.find((owner) => owner.id == institution.collectors[0].id_account)
            institution.name_owner = owner_element ? owner_element.full_name : ""

            commit('UPDATE_SCHOOL', institution)
          }
          resolve(response)
        })
        .catch((error) => { console.log('error', error); reject(error) })
    })
  },
  getPupilsBySchool( context, idSchool) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/pupil/school/${idSchool}`)
      .then(response => {
        return resolve(response.data)
      })
      .catch((error) => { console.log('error', error); reject(error) })
    })
  }
}
