export default {
  SET_USERS(state, users) {
    let staff = []

    users.forEach(element => {
      let staff_user = {
        id : element.account.id,
        name : element.profile.name,
        last_name : element.profile.last_name,
        profile_type : element.profile.profile_type,
        email : element.account.email,
        password : element.account.token,
        card_id : element.profile.card_id,
        birthday_date : element.profile.birthday_date,
        region : element.profile.region,
        username : element.account.username,
        status : element.account.status,
        full_name : element.profile.name + ' ' + element.profile.last_name,
        card_type_id : element.profile.card_type_id,
        address : element.profile.address,
        city : element.profile.city,
        phone_number : element.profile.phone_number,
        zip_code : element.profile.zip_code,
      }
      staff.push(staff_user)
    });
    state.users = staff
  },
  REMOVE_RECORD(state, itemId) {
      const userIndex = state.users.findIndex((u) => u.id == itemId)
      state.users.splice(userIndex, 1)

      const ownerIndex = state.owners.findIndex((u) => u.id == itemId)
      state.owners.splice(ownerIndex, 1)
  },
  ADD_USER(state, user_data) {
    state.users.unshift(user_data)
  },
  UPDATE_USER(state, user_data) {

    let staff_user = {
      id : user_data.account.id,
      name : user_data.profile.name,
      last_name : user_data.profile.last_name,
      profile_type : user_data.profile.profile_type,
      email : user_data.account.email,
      password : user_data.account.token,
      card_id : user_data.profile.card_id,
      birthday_date : user_data.profile.birthday_date,
      region : user_data.profile.region,
      username : user_data.account.username,
      status : user_data.account.status,
      full_name : user_data.profile.name + ' ' + user_data.profile.last_name,
      card_type_id : user_data.profile.card_type_id,
      address : user_data.profile.address,
      city : user_data.profile.city,
      phone_number : user_data.profile.phone_number,
      zip_code : user_data.profile.zip_code,
    }

    const userIndex = state.users.findIndex((u) => u.id == staff_user.id);
    Object.assign(state.users[userIndex], staff_user);
  },
  SET_OWNERS(state, owners) {
    state.owners = []
    owners.forEach(element => {
      let o = {
        id : element.account.id,
        name : element.profile.name,
        last_name : element.profile.last_name,
        profile_type : element.profile.profile_type,
        email : element.account.email,
        password : element.account.token,
        card_id : element.profile.card_id,
        birthday_date : element.profile.birthday_date,
        region : element.profile.region,
        username : element.account.username,
        status : element.account.status,
        full_name : element.profile.name + ' ' + element.profile.last_name,
        card_type_id : element.profile.card_type_id,
        address : element.profile.address,
        city : element.profile.city,
        phone_number : element.profile.phone_number,
        zip_code : element.profile.zip_code,
        company_name: element.profile.company_name,
        company_code: element.profile.company_code,
        // Bank Account
        account_type: element.bank_account.account_type,
        account_name: element.bank_account.account_name,
        bank_name: element.bank_account.bank_name,
        bank_account: element.bank_account.bank_account,
        // Schools
        schools: element.schools,
        stores: element.stores
      }
      state.owners.push(o)
    })
  },
  UPDATE_OWNER(state, owner) {

      let o = {
        id : owner.account.id,
        name : owner.profile.name,
        last_name : owner.profile.last_name,
        profile_type : owner.profile.profile_type,
        email : owner.account.email,
        password : owner.account.token,
        card_id : owner.profile.card_id,
        birthday_date : owner.profile.birthday_date,
        region : owner.profile.region,
        username : owner.account.username,
        status : owner.account.status,
        full_name : owner.profile.name + ' ' + owner.profile.last_name,
        card_type_id : owner.profile.card_type_id,
        address : owner.profile.address,
        city : owner.profile.city,
        phone_number : owner.profile.phone_number,
        zip_code : owner.profile.zip_code,
        company_name: owner.profile.company_name,
        company_code: owner.profile.company_code,
        // Bank Account
        account_type: owner.bank_account.account_type,
        account_name: owner.bank_account.account_name,
        bank_name: owner.bank_account.bank_name,
        bank_account: owner.bank_account.bank_account,
      }

    const userIndex = state.owners.findIndex((u) => u.id == o.id);
    Object.assign(state.owners[userIndex], o);
  },
  ADD_OWNER(state, item) {
    state.owners.unshift(item)
  },
}
